import axios from "axios";
import { useFormik } from "formik";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const GenericPageTemplate = ({ post }) => {
  const [contactSucces, setContactSucces] = useState(false);
  const [contactFail, setContactFail] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: ``,
      companyName: ``,
      website: ``,
      email: ``,
      message: ``,
    },
    onSubmit: async (values) => {
      let { name, companyName, website, email, message } = values;

      let postBody = [
        {
          id: `name`,
          name: `Naam`,
          value: name,
        },
        {
          id: `companyName`,
          name: `Bedrijfsnaam`,
          value: companyName,
        },
        {
          id: `website`,
          name: `Website`,
          value: website,
        },

        {
          id: `email`,
          name: `E-mailadres`,
          value: email,
        },
        {
          id: `message`,
          name: `Bericht`,
          value: message,
        },
      ];

      try {
        await axios.post(`https://mailer.gladior.com/mail`, postBody, {
          headers: {
            "x-api-key": `VHCx3rPEco6n3Vr3YxMNj1D1EJUaPGRb23mBqpuk`,
          },
        });
        try {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "contact",
            contactForm: {
              event: "new-contact",
              url: window.location.pathname,
            },
          });
        } catch (e) {}

        setContactSucces(true);
      } catch (e) {
        setContactFail(true);
      }
    },
  });

  return (
    <>
      <HTMLContent
        className="text-lg mb-2 font-light pt-6 netlify-html text-black "
        content={post.html}
      />

      {post.frontmatter.show_contact_form === true && (
        <>
          <h4 className="text-2xl font-semibold mb-3 font-display mt-10 ">
            Vraag meer informatie aan
          </h4>
          <form
            className="w-full max-w-lg "
            id="contact-form-scans"
            onSubmit={formik.handleSubmit}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Naam (verplicht)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  placeholder="Voornaam Achternaam"
                  required
                  type="text"
                  value={formik.values.name}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="companyName"
                >
                  Bedrijfsnaam
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="companyName"
                  name="companyName"
                  onChange={formik.handleChange}
                  placeholder="Bedrijfsnaam"
                  type="text"
                  value={formik.values.companyName}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="website"
                >
                  Website
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="website"
                  name="website"
                  onChange={formik.handleChange}
                  placeholder="www.voorbeeld.nl"
                  type="text"
                  value={formik.values.website}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  E-mailadres (verplicht)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  placeholder="voorbeeld@website.nl"
                  required
                  type="email"
                  value={formik.values.email}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide  text-xs font-bold mb-2"
                  htmlFor="message"
                >
                  Bericht (verplicht)
                </label>
                <textarea
                  className="resize-y appearance-none block w-full bg-gray-200  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  placeholder="Uw bericht"
                  required
                  value={formik.values.message}
                ></textarea>
              </div>
            </div>

            {contactSucces === true && (
              <div
                className="bg-gray-100 border-l-4 border-gladior-pink text-gladior-pink p-4 mb-6"
                role="alert"
              >
                <p className="font-bold text-gladior-pink">
                  Hartelijk dank voor uw bericht
                </p>

                <p className="text-gladior-pink">
                  Wij streven ernaar uw vraag binnen 5 werkdagen te
                  beantwoorden.
                </p>
              </div>
            )}

            {contactFail === true && (
              <div
                className="bg-gray-100 border-l-4 border-gladior-pink text-gladior-pink p-4 mb-6"
                role="alert"
              >
                <p className="font-bold">Onbekende fout voorgedaan </p>

                <p>Er heeft zich een onbekende fout voorgedaan.</p>
              </div>
            )}

            <button
              className="bg-transparent hover:bg-gladior-pink  text-gladior-pink  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
              type="submit"
            >
              Verzenden
            </button>
          </form>
        </>
      )}
    </>
  );
};

const GenericPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <>
      <SEO
        description={post.frontmatter.seo.meta_description}
        pathname={post.frontmatter.path}
        title={post.frontmatter.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.frontmatter.hero_background_image}
        subtitle={post.frontmatter.subtitle}
        title={post.frontmatter.title}
      >
        <main className="container mx-auto px-6 py-10  max-w-7xl ">
          <GenericPageTemplate content={post.html} post={post} />
        </main>
      </Layout>
    </>
  );
};

GenericPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericPage;

export const genericPageQuery = graphql`
  query GenericPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        hero_background_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        seo {
          meta_title
          meta_description
        }
        subtitle
        show_contact_form
      }
    }
  }
`;
